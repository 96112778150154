import { AnimatePresence } from "framer-motion"
import React, { useState } from "react"
import { FiSliders } from "react-icons/fi"
import { animateScroll } from "react-scroll"
import styled from "styled-components"
import { colors, zIndex } from "../styles/theme"
import { Container } from "./containers/Container"
import FiltersContainer from "./FiltersContainer"
import FilterTags from "./FilterTags"
const StyledContainer = styled(Container)`
  margin-top: 4rem;
  position: relative;
  grid-column: 1/-1;
`
const FilterToggle = styled.button`
  position: fixed;
  z-index: ${zIndex.floating};
  right: 2rem;
  top: 23rem;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-image: linear-gradient(
    to right bottom,
    ${colors.primary.light},
    ${colors.primary.lighter}
  );
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 0.25rem 1rem ${colors.grayscale.default};
  font-size: 1.25em;
  cursor: pointer;

  svg {
    transform: rotate(90deg);
  }

  span {
    color: ${colors.black};
    transition: all 200ms ease;
    padding: 1rem;
    background-color: white;
    border-radius: 1rem;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate3d(0, -110%, 0);
    width: max-content;
    visibility: hidden;
    opacity: 0;

    &::after {
      content: "";
      position: absolute;
      right: 3rem;
      top: 100%;
      margin-right: -0.5rem;
      border-width: 0.5rem;
      border-style: solid;
      border-color: white transparent transparent transparent;
    }
  }

  &:hover,
  &:focus {
    span {
      opacity: 1;
      visibility: visible;
    }
  }
`

const InventoryFilter = (props) => {

  const [isFilterToggled, setIsFilterToggled] = useState(false)

  const toggleFilter = () => {
    setIsFilterToggled((prev) => !isFilterToggled)
    animateScroll.scrollTo(200, {
      smooth: true,
      duration: 200,
      offset: -50,
    })
  }
  return (
    <StyledContainer>
      <FilterTags count={props.count} queryParams={props.queryParams} setQueryParams={props.setQueryParams} />
      <FilterToggle onClick={toggleFilter}>
        <FiSliders />
        <span>Apply Filters</span>
      </FilterToggle>
      <AnimatePresence>
        {isFilterToggled && <FiltersContainer {...props} key="first-child" />}
      </AnimatePresence>
    </StyledContainer>
  )
}

InventoryFilter.propTypes = {}

export default InventoryFilter
