/* eslint-disable react/prop-types */
import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { BiLoaderAlt } from 'react-icons/bi'
import styled, { keyframes } from 'styled-components'
import { Container } from '../components/containers/Container'
import FilteredInventoryList from '../components/FilteredInventoryList'
import InventoryFilter from '../components/InventoryFilter'
import Layout from '../components/layout'
import { PageTitle } from '../components/pagebuilder-parts/index'
import Seo from '../components/Seo'
import { useQueryParamFilters } from '../hooks/useQueryParamFilters'

const spinnerAnimation = keyframes`
  0% {transform: rotate(0deg) scale(.95);}
  100% {transform: rotate(360deg) scale(1.01);}
`
const StyledContainer = styled(Container)`
  display: grid;
  gap: 4rem 2rem;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));

  .loader {
    place-self: center;
    grid-column: 1/-1;
    min-height: 40vh;
    display: grid;
    place-items: center;
    svg {
      animation-name: ${spinnerAnimation};
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      font-size: 4rem;
    }
  }
`
const InventoryPage = ({
  data: {
    allWpInventoryItem: { nodes },
  },
  pageContext: { title },
  location,
}) => {
  const { queryParams, setQueryParams, filters, filteredItems, count } = useQueryParamFilters(nodes)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
  }, [])

  return (
    <Layout>
      <Seo
        title={`${title} Inventory`}
        desc={`Checkout our wide selection of inventory in ${title} available for immediate delivery. ${nodes.length} units currently available.`}
        location={location.pathname}
      />
      <PageTitle backgroundColor="light" title={`${title} Inventory`} location={location} />
      <StyledContainer>
        <InventoryFilter count={count} queryParams={queryParams} setQueryParams={setQueryParams} filters={filters} />
        {loading ? (
          <div className="loader">
            <BiLoaderAlt />
            <p>Items loading...</p>
          </div>
        ) : (
          <FilteredInventoryList inventoryList={filteredItems} count={count} />
        )}
      </StyledContainer>
    </Layout>
  )
}

export default InventoryPage

export const query = graphql`
  query InventoryList($slug: String) {
    allWpInventoryItem(
      filter: { itemLocation: { eq: $slug } }
      sort: {
        fields: [inventoryItemDetails___shedInfo___sizeAsInt, inventoryItemDetails___shedInfo___price]
        order: ASC
      }
    ) {
      nodes {
        id
        title
        itemLocation
        uri
        inventoryItemDetails {
          shedInfo {
            size
            sizeAsInt
            style
            serial
            price
            discountAmount
            productLine
            lotNumber
          }
          saleItem
          shedStatus
          shedLocation {
            ... on WpLocation {
              title
              salesLotInfo {
                locationInfo {
                  city
                  state
                }
              }
            }
          }
        }
        featuredImage {
          node {
            localFile {
              publicURL
              ...Thumbnail
            }
          }
        }
      }
    }
  }
`
