import _camelCase from 'lodash.camelcase'
import { ArrayParam, StringParam, useQueryParams, withDefault } from 'use-query-params'

export function useQueryParamFilters(items) {
  const [query, setQuery] = useQueryParams({
    size: StringParam,
    productLine: StringParam,
    style: StringParam,
    location: StringParam,
    status: StringParam,
    filters: withDefault(ArrayParam, ['size', 'style', 'productLine', 'status', 'location']),
  })
  const filtersInitialValues = {
    size: items
      ?.map(
        ({
          inventoryItemDetails: {
            shedInfo: { size },
          },
        }) => size
      )
      .filter((item, i, arr) => arr.indexOf(item) === i),
    style: items
      ?.map(
        ({
          inventoryItemDetails: {
            shedInfo: { style },
          },
        }) => style
      )
      .filter((item, i, arr) => arr.indexOf(item) === i)
      .sort(),
    productLine: items
      ?.map(
        ({
          inventoryItemDetails: {
            shedInfo: { productLine },
          },
        }) => productLine
      )
      .filter((item, i, arr) => arr.indexOf(item) === i)
      .sort(),
    status: items
      ?.map(({ inventoryItemDetails: { shedStatus } }) => shedStatus)
      .filter((item, i, arr) => arr.indexOf(item) === i)
      .sort(),
    location: items
      ?.map(({ inventoryItemDetails: { shedLocation } }) => shedLocation[0].title)
      .filter((item, i, arr) => arr.indexOf(item) === i)
      .sort(),
  }

  function filterItems(filters, itemList) {
    let filtered = itemList.filter(item => {
      const details = item.inventoryItemDetails?.shedInfo;
      const status = item.inventoryItemDetails?.shedStatus?.toLowerCase();
      return (
        (!filters.size || details?.size?.toLowerCase() === filters.size.toLowerCase()) &&
        (!filters.style || details?.style === filters.style) &&
        (!filters.status || status === filters.status) &&
        (!filters.productLine || details?.productLine === filters.productLine) &&
        (!filters.location || _camelCase(item.inventoryItemDetails?.shedLocation[0]?.title) === filters.location)
      );
    });

    // Function to compare sizes
    // const compareSizes = (sizeA, sizeB) => {
    //   const [widthA, lengthA] = sizeA.split('x').map(Number);
    //   const [widthB, lengthB] = sizeB.split('x').map(Number);
    //   const areaA = widthA * lengthA;
    //   const areaB = widthB * lengthB;
    //   if (areaA !== areaB) {
    //     return areaA - areaB;
    //   } else {
    //     return widthA - widthB;
    //   }
    // };

    // filtered.sort((a, b) => {
    //   const sizeA = a.inventoryItemDetails.shedInfo.size;
    //   const sizeB = b.inventoryItemDetails.shedInfo.size;
    //   return compareSizes(sizeA, sizeB);
    // });

    const specialStyles = ['premiumVinylGazebo', 'premiumWoodGazebo', 'valueWoodGazebo', 'valueVinylGazebo', 'teahouse', 'playhouse'];
    const [specialItems, regularItems] = filtered.reduce(([special, regular], item) => {
      if (specialStyles.includes(item.inventoryItemDetails.shedInfo.style)) {
        return [special.concat(item), regular];
      } else {
        return [special, regular.concat(item)];
      }
    }, [[], []]);

    return regularItems.concat(specialItems);
  }

  const filteredItems = filterItems(query, items)

  return {
    queryParams: query,
    setQueryParams: setQuery,
    filters: Object.entries(filtersInitialValues),
    filteredItems,
    count: filteredItems.length,
  }
}
