/* eslint-disable react/prop-types */
import { AnimatePresence } from 'framer-motion'
import React from 'react'
import InventoryItemCard from './InventoryItemCard'

const InventoryList = ({ inventoryList, count }) => {
  return (
    <>
      <AnimatePresence>
        {inventoryList &&
          inventoryList.length > 0 &&
          inventoryList.map((item) => <InventoryItemCard key={item.id} {...item} />)}
      </AnimatePresence>
      {count === 0 && (
        <div style={{ gridColumn: `1/-1`, textAlign: `center` }}>
          <h2>
            <span role="img" aria-label="surprised emoji">
              😲
            </span>{' '}
            NO INVENTORY?!
          </h2>{' '}
          <p>
            It would seem to be that there is either currently no available inventory, or no available inventory
            matching your selected filters... the sadness.
            <span role="img" aria-label="sad emoji">
              😢
            </span>
          </p>
        </div>
      )}
    </>
  )
}

InventoryList.propTypes = {}

export default InventoryList
