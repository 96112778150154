import { AnimatePresence, motion } from "framer-motion"
import _startCase from 'lodash.startcase'
import React from "react"
import { GrFormClose } from 'react-icons/gr'
import styled from "styled-components"
import { colors } from "../styles/theme"
const TagsList = styled.ul`
  list-style: none;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-bottom: 3rem;

  button > * {
    pointer-events: none;
  }

  > li {
    margin-bottom: 2rem;
    margin-left: 1rem;
    padding: 0.5rem 1.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
    border-radius: 50rem;
    background-image: linear-gradient(
      to right bottom,
      ${colors.primary.light},
      ${colors.primary.lighter}
    );
    color: ${colors.offWhite};
    display: flex;
    align-items: center;
    justify-content: space-between;
    > button,
    > span {
      color: ${colors.offWhite};
    }

    &:not(:first-of-type && nth-of-type(2)) {
      padding-right: 0;
    }

    > button {
      border: none;
      margin: 0 0.5em;
      width: 2rem;
      height: 2rem;
      padding: 0;
      border-radius: 50%;
      background: none;
      cursor: pointer;
      &:hover,
      &:focus {
        background-color: ${colors.primary.default};
      }
    }

    &:hover {
      outline-offset: 3px;
      outline-width: 2px;
      outline-color: ${colors.primary.default};
    }
  }
`

const ScrollToGazebosButton = styled.p`
  cursor: pointer;
  margin-bottom: 2rem;
  margin-left: 1rem;
  margin-top: 0;
  padding: 0.5rem 1.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  border-radius: 50rem;
  background-image: linear-gradient(
    to right bottom,
    ${colors.primary.light},
    ${colors.primary.lighter}
  );
  color: ${colors.offWhite};
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
  }
`

const FilterTags = ({count, className , queryParams, setQueryParams}) => {

  const isFilterSelected =
    Object.values(queryParams).filter( v=> typeof v !== 'object' && v ).length > 0

  const resetQueryParams = Object.keys(queryParams).reduce((acc, current, index) => {
    if(current !== 'filters') {
      acc[current] = undefined;
    }
    return acc
  }, {})

  const removeFilter = (e) => {

    setQueryParams({[e.target.name]: undefined})}

  const clearFilters = () => setQueryParams({...resetQueryParams})
const scrollToFirstSpecialStyle = () => {
  const specialElement = document.querySelector('.specialStyle');
  if (specialElement) {
    const topPosition = specialElement.getBoundingClientRect().top + window.pageYOffset - 120;
    window.scrollTo({ top: topPosition, behavior: 'smooth' });
  }
};

  return (
    <TagsList className={className}>
      <AnimatePresence>
        <motion.li
          whileHover={{ y: -5, scale: 1.01 }}
          positionTransition
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          style={{ cursor: `pointer`, }}
          onClick={scrollToFirstSpecialStyle}
        >
          <span>Scroll to Gazebos & Playhouses</span>
        </motion.li>
      </AnimatePresence>
      {/* <ScrollToGazebosButton>Scroll to Gazebos</ScrollToGazebosButton> */}
      <li>{count} Item{count !== 1 && 's'}</li>
      <AnimatePresence>
        {isFilterSelected &&
          Object.entries(queryParams).filter(v=> v[0] !== 'filters' && v[1])
            .map((tag, index) => {
              return (
                <motion.li
                  key={index}
                  whileHover={{ y: -5, scale: 1.01 }}
                  positionTransition
                  exit={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `${_startCase(tag[1])}`,
                    }}
                  />{" "}
                  <motion.button
                    whileHover={{ scale: 1.3 }}
                    aria-label={`Remove ${tag[0]} filter`}
                    name={tag[0]}
                    onClick={(e) => removeFilter(e)}
                  >
                    <GrFormClose />
                  </motion.button>
                </motion.li>
              )
            })}
      </AnimatePresence>

      {isFilterSelected && (
        <motion.li whileHover={{ y: -5, scale: 1.01 }}>
          <span>Clear All</span>
          <motion.button
            whileHover={{ scale: 1.3 }}
            aria-label={`Clear all filters`}
            onClick={clearFilters}
          >
            <GrFormClose />
          </motion.button>
        </motion.li>
      )}
    </TagsList>
  )
}

FilterTags.propTypes = {}

export default FilterTags
